import React, { useState, useEffect } from "react";
import logo from '../img/logo.svg'
import { Link, useLocation } from "react-router-dom";
import MobileMenu from './MobileMenu'
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";

import lock from '../assets/img/lock.png'

function Header() {
    const [shouldTransition, setShouldTransition] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 100 || location.pathname === '/education') {
                setShouldTransition(true);
            } else {
                setShouldTransition(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.pathname]); // Add location.pathname as a dependency

    const { t } = useTranslation();

    const languages = [
        { value: 'ru', label: 'RU' },
        { value: 'en', label: 'EN' },
        { value: 'pl', label: 'PL' },
        { value: 'fr', label: 'FR' },
        { value: 'de', label: 'DE' },
        { value: 'ro', label: 'RO' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className={`fixed inset-0 z-[900] h-[80px] xl:h-[100px] xl:rounded-[50px] xl:my-[20px] xl:mx-[50px] transition-all duration-500 ${shouldTransition ? 'bg-black' : ''}`}>
            <div className="max-w-[1280px] mx-auto">
                <div className="flex h-[80px] xl:h-[100px] items-center justify-between">
                    <Link to="/"><img src={logo} alt="" className="xl:w-[133px] xl:h-[70px] w-[84px] h-[49px] mx-[20px] xl:mx-0"/></Link>
                    <div className="xl:flex gap-[30px] hidden">
                        <Link to="/"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/' ? 'font-medium' : 'font-light'}`}>{t('header.1')}</p></Link>
                        <div className="flex items-center gap-[5px] mt-[-7px]">
                            <Link to="/education"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/education' ? 'font-medium' : 'font-light'}`}>{t('header.2')}</p></Link>
                            <img src={lock} alt="" />
                        </div>
                        <Link to="/accounts"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/accounts' ? 'font-medium' : 'font-light'}`}>{t('header.8')}</p></Link>
                        <Link to="/contacts"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/contacts' ? 'font-medium' : 'font-light'}`}>{t('header.3')}</p></Link>
                        <Link to="/news"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/news' ? 'font-medium' : 'font-light'}`}>{t('header.4')}</p></Link>
                        <Link to="/calendar"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/calendar' ? 'font-medium' : 'font-light'}`}>{t('header.5')}</p></Link>
                        <Link to="/faq"><p className={`inter text-white text-[16px] xl:text-[16px] ${location.pathname === '/faq' ? 'font-medium' : 'font-light'}`}>{t('header.9')}</p></Link>
                        <Dropdown
                            options={languages}
                            placeholder="EN"
                            onChange={handleChangeLang}
                            className="mt-[-8px]"
                            value={languages.find(lang => lang.value === currentLang)}
                        />
                        <Link to="https://user.bridgeinvestmarket.com/"><div className="border border-[#F75524] rounded-[10px] py-[2px] px-[20px] mt-[-2px]">
                            <p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('header.7')}</p>
                        </div></Link>
                        <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('header.6')}</p></Link>
                    </div>
                    <div className="flex xl:hidden mx-[20px]">
                        <Dropdown
                            options={languages}
                            placeholder="EN"
                            onChange={handleChangeLang}
                            className="mt-[-5px]"
                            value={languages.find(lang => lang.value === currentLang)}
                        />
                        <MobileMenu/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
